import React from "react";
import css from './logistic2.module.scss';
import BackToTraceabilityButton from "../../shared/ButtonBackTo/BackToTraceabilityButton";
import AdditionalInformation from "../../shared/additionalInformation/additionalInformation";
import Footer from "../../shared/Footer/Footer";
import {getRoutes} from "../../shared/routes";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_logistic_2"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 420, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Logistic2 = () => {
    const routes = getRoutes();
    const title = routes.TraceabilityLogistics.pageTitle;
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    return (
        <div className={css.wrapper}>
            <div className={css.title}>{title}</div>
            <div className={css.imageSectionWrapper}>
                <div className={css.threeImagesWrapper}>
                    <div className={[css.imageWrapper, css.img1].join(' ')}>
                        {renderImageByFileName(imagesFluid, 'digital_delivery_note.png', 'digital delivery note')}
                        <div className={css.imageText}>
                            logistics provider data
                        </div>
                    </div>
                    <div className={[css.imageWrapper, css.img2].join(' ')}>
                        {renderImageByFileName(imagesFluid, 'gps_record_track_data_record.png', 'gps record track data record')}
                        <div className={css.imageText}>
                            GPS record track data record, if available
                        </div>
                    </div>
                    <div className={[css.imageWrapper, css.img3].join(' ')}>
                        {renderImageByFileName(imagesFluid, 'vehicle_registration_number.png', 'vehicle registration number')}
                        <div className={css.imageText}>
                            vehicle registration number, container number
                        </div>
                    </div>
                </div>
                <div className={[css.imageWrapper, css.img4].join(' ')}>
                    {renderImageByFileName(imagesFluid, 'marked_log_on_the_trailer.png', 'marked log on trailer')}
                    <div className={css.imageText} style={{paddingRight: 100}}>
                        apply at least one <br/>log marking based on Dcode
                    </div>
                </div>
                <div className={[css.imageWrapper, css.img5].join(' ')}>
                    {renderImageByFileName(imagesFluid, 'alphanumeric_marking_of_logs_on_the_trailer.png', 'alphanumeric marking of logs on the trailer')}
                    <div className={css.imageText}>
                        set of images of <br/>the loaded vehicle, container
                    </div>
                </div>
            </div>
            <BackToTraceabilityButton/>

            <AdditionalInformation/>

            <Footer/>

        </div>
    )
};

export default Logistic2;
