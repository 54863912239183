import React from 'react';
import {getRoutes} from "../../components/shared/routes";
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import Logistic2 from "../../components/sections/Logistic2/Logistic2";

const LogisticsPage = () => {
    const routes = getRoutes();
    const pageTitle = routes.TraceabilityLogistics.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <Breadcrumbs
                title={pageTitle}
                parentTitles={[routes.Traceability.pageTitle]}
                parentRoutes={[routes.Traceability]}
            />

            <Logistic2 />
        </Layout>
    );
};

export default LogisticsPage;
